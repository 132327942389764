import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="monsters-types-subtypes-and-special-abilities"></a><h2>Monsters</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Types, Subtypes, and Special Abilities</span>
    </h2>
    <p><b><a id="aberration"></a>Aberration Type</b>: An aberration
has a bizarre anatomy,
strange abilities, an alien mindset, or any combination of the three.</p>
    <p><i>Features</i>: An aberration has the following features. </p>
    <ul>
      <li>
        <p>d8 Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An aberration possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons. If generally humanoid
in
form, proficient with all simple weapons and any weapon it is described
as using.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) it is described as wearing, as well as all lighter types.
Aberrations not indicated as wearing armor are not proficient with
armor. Aberrations are proficient with shields if they are proficient
with any form of armor.</p>
      </li>
      <li>
        <p>Aberrations eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="ability-score-loss"></a>Ability Score Loss (Su)</b>:
Some attacks reduce the
opponent&rsquo;s score in one or more abilities. This loss can be
temporary
(ability damage) or permanent (ability drain).</p>
    <p><i>Ability Damage</i>: This attack damages an opponent&rsquo;s
ability score. The creature&rsquo;s descriptive text gives the ability
and
the amount of damage. If an attack that causes ability damage scores a
critical hit, it deals twice the indicated amount of damage (if the
damage is expressed as a die range, roll two dice). Ability damage
returns at the rate of 1 point per day for each affected ability.</p>
    <p><i>Ability Drain</i>: This effect permanently reduces a living
opponent&rsquo;s ability score when the creature hits with a melee
attack.
The creature&rsquo;s descriptive text gives the ability and the amount
drained. If an attack that causes ability drain scores a critical hit,
it drains twice the indicated amount (if the damage is expressed as a
die range, roll two dice). Unless otherwise specified in the
creature&rsquo;s
description, a draining creature gains 5 temporary hit points (10 on a
critical hit) whenever it drains an ability score no matter how many
points it drains. Temporary hit points gained in this fashion last for
a maximum of 1 hour.</p>
    <p>Some ability drain attacks allow a Fortitude save (DC 10 + 1/2
draining creature&rsquo;s racial HD + draining creature&rsquo;s Cha
modifier; the
exact DC is given in the creature&rsquo;s descriptive text). If no
saving
throw is mentioned, none is allowed.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#ability-score-loss" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ability Score Loss</a> under Special
Abilities.
    </p>
    <p><b><a id="alternate-form"></a>Alternate Form (Su)</b>: A
creature with this special
quality has the ability to assume one or more specific alternate forms.
A <a style={{
        "color": "#579eb6"
      }} href="spellsTtoZ.html#true-seeing">true seeing</a> spell or ability reveals the creature�s
natural form. A creature using alternate form reverts to
its natural form when killed, but separated body parts
retain their shape. A creature cannot use alternate form
to take the form of a creature with a template.
Assuming an alternate form results in the following changes to the creature: </p>
    <ul>
      <li>
        <p>The creature retains the type and subtype of its original
form. It gains the size of its new form. If the new form has the <a style={{
            "color": "#579eb6"
          }} href="#aquatic">aquatic subtype</a>, the creature
gains that subtype as well.</p>
      </li>
      <li>
        <p>The creature loses the natural weapons, natural
armor, and movement modes of its original form, as
well as any extraordinary special attacks of its original
form not derived from class levels (such as the
barbarian�s rage class feature).</p>
      </li>
      <li>
        <p>The creature gains the natural weapons, natural armor,
movement modes, and extraordinary special attacks of its new form.</p>
      </li>
      <li>
        <p>The creature retains the special qualities of its original
form. It does not gain any special qualities of its new form.</p>
      </li>
      <li>
        <p>The creature retains the spell-like abilities and
supernatural attacks of its old form (except for breath weapons and
gaze attacks). It does not gain the spell-like abilities or
supernatural attacks of its new form.</p>
      </li>
      <li>
        <p>The creature gains the physical ability scores (Str, Dex,
Con) of its new form. It retains the mental ability scores (Int, Wis,
Cha) of its original form. Apply any changed physical ability score modifiers in
all appropriate areas with one exception: the creature
retains the hit points of its original form despite any
change to its Constitution.</p>
      </li>
      <li>
        <p>Except as described elsewhere, the creature retains
all other game statistics of its original form, including
(but not necessarily limited to) HD, hit points, skill
ranks, feats, base attack bonus, and base save bonuses.</p>
      </li>
      <li>
        <p>The creature retains any spellcasting ability it had in
its
original form, although it must be able to speak intelligibly to cast
spells with verbal components and it must have humanlike hands to cast
spells with somatic components.</p>
      </li>
      <li>
        <p>The creature is effectively camouflaged as a creature of
its
new form, and it gains a +10 bonus on <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="skillsAll.html#disguise">Disguise</a>
checks if it uses this
ability to create a disguise.</p>
      </li>
      <li><p>Any gear worn or carried by the creature that can�t be
worn or carried in its new form instead falls to the
ground in its space. If the creature changes size, any
gear it wears or carries that can be worn or carried in its
new form changes size to match the new size.
(Nonhumanoid-shaped creatures can�t wear armor
designed for humanoid-shaped creatures, and viceversa.)
Gear returns to normal size if dropped.</p></li>
    </ul>
    <p><b><a id="air"></a>Air Subtype</b>: This subtype usually is
used for
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#elemental">elementals</a>
and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#outsider">outsiders</a>
with a connection to the Elemental Plane Air.
Air creatures always have fly speeds and usually have perfect
maneuverability.</p>
    <p><b><a id="angel"></a>Angel Subtype</b>: Angels are a race of
celestials, or good
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#outsider">outsiders</a>,
native to the good-aligned Outer Planes.</p>
    <p><i>Traits</i>: An angel possesses the following traits (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet and low-light vision.</p>
      </li>
      <li>
        <p>Immunity to acid, cold, and petrification.</p>
      </li>
      <li>
        <p>Resistance to electricity 10 and fire 10.</p>
      </li>
      <li>
        <p>&nbsp;+4 racial bonus on saves against poison.</p>
      </li>
      <li>
        <p>Protective Aura (Su): Against attacks made or effects
created
by evil creatures, this ability provides a +4 deflection bonus to AC
and a +4 resistance bonus on saving throws to anyone within 20 feet of
the angel. Otherwise, it functions as a <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsMtoO.html#magic-circle-against-evil">magic circle against
evil</a>
effect and a <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsHtoL.html#lesser-globe-of-invulnerability">lesser globe of
invulnerability</a>, both with a radius of 20
feet (caster level equals angel&rsquo;s HD). (The defensive benefits
from the
circle are not included in an angel&rsquo;s statistics block.)</p>
      </li>
      <li>
        <p>Tongues (Su): All angels can speak with any creature that
has
a language, as though using a <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsTtoZ.html#tongues">tongues</a> spell (caster level equal
to
angel&rsquo;s Hit Dice). This ability is always active.</p>
      </li>
    </ul>
    <p><b><a id="animal"></a>Animal Type</b>: An animal is a
living, nonhuman creature,
usually a vertebrate with no magical abilities and no innate capacity
for language or culture.</p>
    <p><i>Features</i>: An animal has the following features (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>d8 Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Fortitude and Reflex saves (certain animals have
different good saves).</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An animal possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Intelligence score of 1 or 2 (no creature with an
Intelligence score of 3 or higher can be an animal).</p>
      </li>
      <li>
        <p>Low-light vision.</p>
      </li>
      <li>
        <p>Alignment: Always neutral.</p>
      </li>
      <li>
        <p>Treasure: None.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons only. A noncombative
herbivore uses its natural weapons as a secondary attack. Such attacks
are made with a &ndash;5 penalty on the creature&rsquo;s attack rolls,
and the
animal receives only 1/2 its Strength modifier as a damage adjustment.</p>
      </li>
      <li>
        <p>Proficient with no armor unless trained for war.</p>
      </li>
      <li>
        <p>Animals eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="aquatic"></a>Aquatic Subtype</b>: These creatures
always have swim
speeds and thus can move in water without making Swim checks. An
aquatic creature can breathe underwater. It cannot also breathe air
unless it has the amphibious special quality. </p>
    <p><b><a id="archon"></a>Archon Subtype</b>: Archons are a race
of celestials, or
good <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#outsider">outsiders</a>,
native to lawful good-aligned Outer Planes.</p>
    <p><i>Traits</i>: An archon possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet and low-light vision.</p>
      </li>
      <li>
        <p>Aura of Menace (Su): A righteous aura surrounds archons
that
fight or get angry. Any hostile creature within a 20-foot radius of an
archon must succeed on a Will save to resist its effects. The save DC
varies with the type of archon, is Charisma-based, and includes a +2
racial bonus. Those who fail take a &ndash;2 penalty on attacks, AC,
and
saves for 24 hours or until they successfully hit the archon that
generated the aura. A creature that has resisted or broken the effect
cannot be affected again by the same archon&rsquo;s aura for 24 hours.</p>
      </li>
      <li>
        <p>Immunity to electricity and petrification.</p>
      </li>
      <li>
        <p>+4 racial bonus on saves against poison.</p>
      </li>
      <li>
        <p><a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsMtoO.html#magic-circle-against-evil">Magic Circle against
Evil</a> (Su): A magic circle against
evil
effect always surrounds an archon (caster level equals the
archon&rsquo;s Hit
Dice). (The defensive benefits from the circle are not included in an
archon&rsquo;s statistics block.)</p>
      </li>
      <li>
        <p>Teleport (Su): Archons can use <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsTtoZ.html#greater-teleport">greater teleport</a> at will,
as
the spell (caster level 14th), except that the creature can transport
only itself and up to 50 pounds of objects.</p>
      </li>
      <li>
        <p>Tongues (Su): All archons can speak with any creature that
has a language, as though using a <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsTtoZ.html#tongues">tongues</a> spell (caster level 14th).
This ability is always active.</p>
      </li>
    </ul>
    <p><b><a id="augmented"></a>Augmented Subtype</b>: A creature
receives this subtype
whenever something happens to change its original type. Some creatures
(those with an inherited template) are born with this subtype; others
acquire it when they take on an acquired template. The augmented
subtype is always paired with the creature&rsquo;s original type. A
creature
with the augmented subtype usually has the traits of its current type,
but the features of its original type.
    </p>
    <p><b><a id="blindsense"></a>Blindsense (Ex)</b>: Using
nonvisual senses, such as acute
smell or hearing, a creature with blindsense notices things it cannot
see. The creature usually does not need to make Spot or Listen checks
to pinpoint the location of a creature within range of its blindsense
ability, provided that it has line of effect to that creature. Any
opponent the creature cannot see still has total concealment against
the creature with blindsense, and the creature still has the normal
miss chance when attacking foes that have concealment. Visibility still
affects the movement of a creature with blindsense. A creature with
blindsense is still denied its Dexterity bonus to Armor Class against
attacks from creatures it cannot see.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#blindsight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blindsight and Blindsense</a> under
Special Abilities.</p>
    <p><b><a id="blindsight"></a>Blindsight (Ex)</b>: This ability
is similar to blindsense,
but is far more discerning. Using nonvisual senses, such as sensitivity
to vibrations, keen smell, acute hearing, or echolocation, a creature
with blindsight maneuvers and fights as well as a sighted creature.
Invisibility, darkness, and most kinds of concealment are irrelevant,
though the creature must have line of effect to a creature or object to
discern that creature or object. The ability&rsquo;s range is specified
in
the creature&rsquo;s descriptive text. The creature usually does not
need to
make Spot or Listen checks to notice creatures within range of its
blindsight ability. Unless noted otherwise, blindsight is continuous,
and the creature need do nothing to use it. Some forms of blindsight,
however, must be triggered as a free action. If so, this is noted in
the creature&rsquo;s description. If a creature must trigger its
blindsight
ability, the creature gains the benefits of blindsight only during its
turn.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#blindsight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blindsight and Blindsense</a> under
Special Abilities.</p>
    <p><b><a id="breath-weapon"></a>Breath Weapon (Su)</b>: A
breath weapon attack usually
deals damage and is often based on some type of energy.</p>
    <p>Such breath weapons allow a Reflex save for half damage (DC 10
+ 1/2 breathing creature&rsquo;s racial HD + breathing creature&rsquo;s
Con
modifier; the exact DC is given in the creature&rsquo;s descriptive
text). A
creature is immune to its own breath weapon unless otherwise noted.
Some breath weapons allow a Fortitude save or a Will save instead of a
Reflex save.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#breath-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>Breath Weapon</a> under Special
Abilities.</p>
    <p><b><a id="change-shape"></a>Change Shape (Su)</b>: A
creature with this special quality
has the ability to assume the appearance of a specific creature or type
of creature (usually a humanoid), but retains most of its own physical
qualities. A true seeing spell or ability reveals the creature�s
natural form. A creature using change shape reverts to
its natural form when killed, but separated body parts
retain their shape. A creature cannot use change shape
to take the form of a creature with a template.
Changing shape results in the following changes to the creature: </p>
    <ul>
      <li>
        <p>The creature retains the type and subtype of its original
form. It gains the size of its new form.</p>
      </li>
      <li>
        <p>The creature loses the natural weapons and
movement modes of its original form, as well as any
extraordinary special attacks of its original form not
derived from class levels (such as the barbarian�s rage
class feature).</p>
      </li>
      <li>
        <p>The creature gains the natural weapons, movement modes,
and
extraordinary special attacks of its new form.</p>
      </li>
      <li>
        <p>The creature retains all other special attacks and
qualities
of its original form, except for breath weapons and gaze attacks.</p>
      </li>
      <li>
        <p>The creature retains the ability scores of its original
form.</p>
      </li>
      <li>
        <p>Except as described elsewhere, the creature retains
all other game statistics of its original form, including
(but not necessarily limited to) HD, hit points, skill
ranks, feats, base attack bonus, and base save bonuses.</p>
      </li>
      <li>
        <p>The creature retains any spellcasting ability it had in
its
original form, although it must be able to speak intelligibly to cast
spells with verbal components and it must have humanlike hands to cast
spells with somatic components.</p>
      </li>
      <li>
        <p>The creature is effectively camouflaged as a creature of
its
new form, and gains a +10 bonus on Disguise checks if it uses this
ability to create a disguise.</p>
      </li>
      <li><p>Any gear worn or carried by the creature that can�t be
worn or carried in its new form instead falls to the
ground in its space. If the creature changes size, any
gear it wears or carries that can be worn or carried in its
new form changes size to match the new size.
(Nonhumanoid-shaped creatures can�t wear armor
designed for humanoid-shaped creatures, and viceversa.)
Gear returns to normal size if dropped.</p></li>
    </ul>
    <p><b><a id="chaotic"></a>Chaotic Subtype</b>: A subtype
usually applied only to
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#outsider">outsiders</a>
native to the chaotic-aligned Outer Planes. Most creatures
that have this subtype also have chaotic alignments; however, if their
alignments change they still retain the subtype. Any effect that
depends on alignment affects a creature with this subtype as if the
creature has a chaotic alignment, no matter what its alignment actually
is. The creature also suffers effects according to its actual
alignment. A creature with the chaotic subtype overcomes <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#DR">damage
reduction</a> as if its natural weapons and any weapons it wields were
chaotic-aligned.</p>
    <p><b><a id="cold"></a>Cold Subtype</b>: A creature with the
cold subtype has
immunity to cold. It has <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#vulnerability-to-energy">vulnerability to fire</a>, which means
it takes
half again as much (+50%) damage as normal from fire, regardless of
whether a saving throw is allowed, or if the save is a success or
failure. </p>
    <p><b><a id="constrict"></a>Constrict (Ex)</b>: A creature with
this special attack can
crush an opponent, dealing bludgeoning damage, after making a
successful <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="specialAttacks.html#grapple">grapple</a> check. The amount of
damage is given in the
creature&rsquo;s entry. If the creature also has the <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#improved-grab">improved grab</a>
ability it
deals constriction damage in addition to damage dealt by the weapon
used to grab.</p>
    <p><b><a id="construct"></a>Construct Type</b>: A construct is
an animated object or
artificially constructed creature.</p>
    <p><i>Features</i>: A construct has the following features. </p>
    <ul>
      <li>
        <p>10-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>No good saving throws.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, if the
construct has an Intelligence score. However, most constructs are
mindless and gain no skill points or feats.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A construct possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>No Constitution score.</p>
      </li>
      <li>
        <p>Low-light vision.</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Immunity to all mind-affecting effects (charms,
compulsions,
phantasms, patterns, and morale effects).</p>
      </li>
      <li>
        <p>Immunity to <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#poison">poison</a>, sleep effects, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#paralyzed">paralysis</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#stunned">stunning</a>,
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#disease">disease</a>, death effects,
and necromancy effects.</p>
      </li>
      <li>
        <p>Cannot heal damage on their own, but often can be repaired
by
exposing them to a certain kind of effect (see the creature&rsquo;s
description for details) or through the use of the <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="monsterFeats.html#craft-construct">Craft Construct</a>
feat. A construct with the <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="#fast-healing">fast healing</a> special quality still benefits
from that quality.</p>
      </li>
      <li>
        <p>Not subject to critical hits, nonlethal damage, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#ability-damaged">ability
damage</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="#ability-drained">ability drain</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="#fatigued">fatigue</a>, <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="#exhausted">exhaustion</a>, or
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="abilitiesAndConditions.html#energy-drained">energy drain</a>.</p>
      </li>
      <li>
        <p>Immunity to any effect that requires a Fortitude save
(unless
the effect also works on objects, or is harmless).</p>
      </li>
      <li>
        <p>Not at risk of death from massive damage. Immediately
destroyed when reduced to 0 hit points or less.</p>
      </li>
      <li>
        <p>Since it was never alive, a construct cannot be <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsPtoR.html#raise-dead">raised</a>
or
          <a style={{
            "color": "rgb(87, 158, 182)"
          }} href="spellsPtoR.html#resurrection">resurrected</a>.</p>
      </li>
      <li>
        <p>Because its body is a mass of unliving matter, a construct
is
hard to destroy. It gains bonus hit points based on size, as shown on
the following table.</p>
      </li>
    </ul>
    <a id="table-construct-bonus-hit-points"></a><table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Construct Size</th>
          <th style={{
            "width": "125px"
          }}>Bonus Hit Points</th>
        </tr>
        <tr className="odd-row">
          <td>Fine</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>Diminutive</td>
          <td>&mdash;</td>
        </tr>
        <tr className="odd-row">
          <td>Tiny</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>Small</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>Medium</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Large</td>
          <td>30</td>
        </tr>
        <tr className="odd-row">
          <td>Huge</td>
          <td>40</td>
        </tr>
        <tr>
          <td>Gargantuan</td>
          <td>60</td>
        </tr>
        <tr className="odd-row">
          <td>Colossal</td>
          <td>80</td>
        </tr>
      </tbody>
    </table>
    <p> </p>
    <ul>
      <li>
        <p>Proficient with its natural weapons only, unless generally
humanoid in form, in which case proficient with any weapon mentioned in
its entry.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Constructs do not eat, sleep, or breathe.</p>
      </li>
    </ul>
    <p><b><a id="DR"></a><a id="damage-reduction"></a>Damage Reduction (Ex or Su)</b>: A
creature with this
special quality ignores damage from most weapons and natural attacks.
Wounds heal immediately, or the weapon bounces off harmlessly (in
either case, the opponent knows the attack was ineffective). The
creature takes normal damage from energy attacks (even nonmagical
ones), spells, spell-like abilities, and supernatural abilities. A
certain kind of weapon can sometimes damage the creature normally, as
noted below.</p>
    <p>The entry indicates the amount of damage ignored (usually 5 to
15 points) and the type of weapon that negates the ability. </p>
    <p>Some monsters are vulnerable to piercing, bludgeoning, or
slashing damage. </p>
    <p>Some monsters are vulnerable to certain materials, such as
alchemical silver, adamantine, or cold-forged iron. Attacks from
weapons that are not made of the correct material have their damage
reduced, even if the weapon has an enhancement bonus.</p>
    <p>Some monsters are vulnerable to magic weapons. Any weapon with
at least a +1 magical enhancement bonus on attack and damage rolls
overcomes the damage reduction of these monsters. Such creatures&rsquo;
natural weapons (but not their attacks with weapons) are treated as
magic weapons for the purpose of overcoming damage reduction.</p>
    <p>A few very powerful monsters are vulnerable only to epic
weapons; that is, magic weapons with at least a +6 enhancement bonus.
Such creatures&rsquo; natural weapons are also treated as epic weapons
for
the purpose of overcoming damage reduction.</p>
    <p>Some monsters are vulnerable to chaotic-, evil-, good-, or
lawful-aligned weapons. When a cleric casts align weapon, affected
weapons might gain one or more of these properties, and certain magic
weapons have these properties as well. A creature with an alignment
subtype (chaotic, evil, good, or lawful) can overcome this type of
damage reduction with its natural weapons and weapons it wields as if
the weapons or natural weapons had an alignment (or alignments) that
match the subtype(s) of the creature. </p>
    <p>When a damage reduction entry has a dash (&ndash;) after the
slash,
no weapon negates the damage reduction.</p>
    <p>A few creatures are harmed by more than one kind of weapon. A
weapon of either type overcomes this damage reduction.</p>
    <p>A few other creatures require combinations of different types
of attacks to overcome their damage reduction. A weapon must be both
types to overcome this damage reduction. A weapon that is only one type
is still subject to damage reduction.</p>
Also see <a href="abilitiesAndConditions.html#damage-reduction" style={{
      "color": "rgb(87, 158, 182)"
    }}>Damage Reduction</a> under Special
Abilities.
    <p><b><a id="dragon"></a>Dragon Type</b>: A dragon is a
reptilelike creature,
usually winged, with magical or unusual abilities.</p>
    <p><i>Features</i>: A dragon has the following features. </p>
    <ul>
      <li>
        <p>12-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to total Hit Dice (as fighter).</p>
      </li>
      <li>
        <p>Good Fortitude, Reflex, and Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (6 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A dragon possesses the following traits (unless
otherwise noted in the description of a particular kind). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet and low-light vision.</p>
      </li>
      <li>
        <p>Immunity to magic sleep effects and paralysis effects.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons only unless humanoid
in
form (or capable of assuming humanoid form), in which case proficient
with all simple weapons and any weapons mentioned in its entry.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Dragons eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="earth"></a>Earth Subtype</b>: This subtype usually
is used for
elementals and outsiders with a connection to the Elemental Plane of
Earth. Earth creatures usually have burrow speeds, and most earth
creatures can burrow through solid rock.</p>
    <p><b><a id="elemental"></a>Elemental Type</b>: An elemental is
a being composed of one
of the four classical elements: air, earth, fire, or water.</p>
    <p><i>Features</i>: An elemental has the following features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good saves depend on the element: Fortitude (earth, water)
or
Reflex (air, fire).</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An elemental possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Immunity to poison, sleep effects, paralysis, and stunning.</p>
      </li>
      <li>
        <p>Not subject to critical hits or flanking.</p>
      </li>
      <li>
        <p>Unlike most other living creatures, an elemental does not
have a dual nature&mdash;its soul and body form one unit. When an
elemental
is slain, no soul is set loose. Spells that restore souls to their
bodies, such as raise dead, reincarnate, and resurrection, don&rsquo;t
work
on an elemental. It takes a different magical effect, such as limited
wish, wish, miracle, or true resurrection, to restore it to life.</p>
      </li>
      <li>
        <p>Proficient with natural weapons only, unless generally
humanoid in form, in which case proficient with all simple weapons and
any weapons mentioned in its entry.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) that it is described as wearing, as well as all lighter types.
Elementals not indicated as wearing armor are not proficient with
armor. Elementals are proficient with shields if they are proficient
with any form of armor.</p>
      </li>
      <li>
        <p>Elementals do not eat, sleep, or breathe.</p>
      </li>
    </ul>
    <p><b><a id="energy-drain"></a>Energy Drain (Su)</b>: This
attack saps a living opponent&rsquo;s
vital energy and happens automatically when a melee or ranged attack
hits. Each successful energy drain bestows one or more negative levels
(the creature&rsquo;s description specifies how many). If an attack
that
includes an energy drain scores a critical hit, it drains twice the
given amount. Unless otherwise specified in the creature&rsquo;s
description,
a draining creature gains 5 temporary hit points (10 on a critical hit)
for each negative level it bestows on an opponent. These temporary hit
points last for a maximum of 1 hour. An affected opponent takes a
&ndash;1
penalty on all skill checks and ability checks, attack rolls, and
saving throws, and loses one effective level or Hit Die (whenever level
is used in a die roll or calculation) for each negative level. A
spellcaster loses one spell slot of the highest level of spells she can
cast and (if applicable) one prepared spell of that level; this loss
persists until the negative level is removed. Negative levels remain
until 24 hours have passed or until they are removed with a spell, such
as restoration. If a negative level is not removed before 24 hours have
passed, the affected creature must attempt a Fortitude save (DC 10 +
1/2 draining creature&rsquo;s racial HD + draining creature&rsquo;s Cha
modifier;
the exact DC is given in the creature&rsquo;s descriptive text). On a
success, the negative level goes away with no harm to the creature. On
a failure, the negative level goes away, but the creature&rsquo;s level
is
also reduced by one. A separate saving throw is required for each
negative level.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#energy-drain" style={{
        "color": "rgb(87, 158, 182)"
      }}>Energy Drain and Negative Levels</a>
under Special Abilities.</p>
    <p><b><a id="evil"></a>Evil Subtype</b>: A subtype usually
applied only to
outsiders native to the evil-aligned Outer Planes. Evil outsiders are
also called fiends. Most creatures that have this subtype also have
evil alignments; however, if their alignments change, they still retain
the subtype. Any effect that depends on alignment affects a creature
with this subtype as if the creature has an evil alignment, no matter
what its alignment actually is. The creature also suffers effects
according to its actual alignment. A creature with the evil subtype
overcomes damage reduction as if its natural weapons and any weapons it
wields were evil-aligned (see Damage Reduction, above).</p>
    <p><b><a id="extraplanar"></a>Extraplanar Subtype</b>: A
subtype applied to any creature
when it is on a plane other than its native plane. A creature that
travels the planes can gain or lose this subtype as it goes from plane
to plane. Monster entries assume that encounters with creatures take
place on the Material Plane, and every creature whose native plane is
not the Material Plane has the extraplanar subtype (but would not have
when on its home plane). Every extraplanar creature in this book has a
home plane mentioned in its description. Creatures not labeled as
extraplanar are natives of the Material Plane, and they gain the
extraplanar subtype if they leave the Material Plane. No creature has
the extraplanar subtype when it is on a transitive plane, such as the
Astral Plane, the Ethereal Plane, and the Plane of Shadow.</p>
    <p><b><a id="fast-healing"></a>Fast Healing (Ex)</b>: A
creature with the fast healing
special quality regains hit points at an exceptionally fast rate,
usually 1 or more hit points per round, as given in the
creature&rsquo;s
entry. Except where noted here, fast healing is just like natural
healing. Fast healing does not restore hit points lost from starvation,
thirst, or suffocation, and it does not allow a creature to regrow lost
body parts. Unless otherwise stated, it does not allow lost body parts
to be reattached.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#fast-healing" style={{
        "color": "rgb(87, 158, 182)"
      }}>Fast Healing</a> under Special
Abilities.</p>
    <p><b><a id="fear"></a>Fear (Su or Sp)</b>: Fear attacks can
have various effects.</p>
    <p><i>Fear Aura (Su)</i>: The use of this ability is a free
action. The aura can freeze an opponent (such as a mummy&rsquo;s
despair) or
function like the fear spell. Other effects are possible. A fear aura
is an area effect. The descriptive text gives the size and kind of area.</p>
    <p><i>Fear Cones (Sp) and Rays (Su)</i>: These effects usually
work like the fear spell. </p>
    <p>If a fear effect allows a saving throw, it is a Will save (DC
10 + 1/2 fearsome creature&rsquo;s racial HD + creature&rsquo;s Cha
modifier; the
exact DC is given in the creature&rsquo;s descriptive text). All fear
attacks
are mind-affecting fear effects.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#fear" style={{
        "color": "rgb(87, 158, 182)"
      }}>Fear</a> under Special Abilities.</p>
    <p><b><a id="fey"></a>Fey Type</b>: A fey is a creature with
supernatural
abilities and connections to nature or to some other force or place.
Fey are usually human-shaped.</p>
    <p><i>Features</i>: A fey has the following features. </p>
    <ul>
      <li>
        <p>6-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 1/2 total Hit Dice (as wizard).</p>
      </li>
      <li>
        <p>Good Reflex and Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (6 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A fey possesses the following traits (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Low-light vision.</p>
      </li>
      <li>
        <p>Proficient with all simple weapons and any weapons
mentioned
in its entry.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) that it is described as wearing, as well as all lighter types.
Fey not indicated as wearing armor are not proficient with armor. Fey
are proficient with shields if they are proficient with any form of
armor.</p>
      </li>
      <li>
        <p>Fey eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="fire"></a>Fire Subtype</b>: A creature with the
fire subtype has
immunity to fire. It has vulnerability to cold, which means it takes
half again as much (+50%) damage as normal from cold, regardless of
whether a saving throw is allowed, or if the save is a success or
failure.</p>
    <p><b><a id="flight"></a>Flight (Ex or Su)</b>: A creature with
this ability can
cease or resume flight as a free action. If the ability is
supernatural, it becomes ineffective in an antimagic field, and the
creature loses its ability to fly for as long as the antimagic effect
persists.</p>
    <p><b><a id="rightful-presence"></a>Frightful Presence (Ex)</b>:
This special quality makes a
creature&rsquo;s very presence unsettling to foes. It takes effect
automatically when the creature performs some sort of dramatic action
(such as charging, attacking, or snarling). Opponents within range who
witness the action may become frightened or shaken. Actions required to
trigger the ability are given in the creature&rsquo;s descriptive text.
The
range is usually 30 feet, and the duration is usually 5d6 rounds. This
ability affects only opponents with fewer Hit Dice or levels than the
creature has. An affected opponent can resist the effects with a
successful Will save (DC 10 + 1/2 frightful creature&rsquo;s racial HD
+
frightful creature&rsquo;s Cha modifier; the exact DC is given in the
creature&rsquo;s descriptive text). An opponent that succeeds on the
saving
throw is immune to that same creature&rsquo;s frightful presence for 24
hours. Frightful presence is a mind-affecting fear effect. </p>
    <p><b><a id="gaze"></a>Gaze (Su)</b>: A gaze special attack
takes effect when
opponents look at the creature&rsquo;s eyes. The attack can have almost
any
sort of effect: petrification, death, charm, and so on. The typical
range is 30 feet, but check the creature&rsquo;s entry for details. The
type
of saving throw for a gaze attack varies, but it is usually a Will or
Fortitude save (DC 10 + 1/2 gazing creature&rsquo;s racial HD + gazing
creature&rsquo;s Cha modifier; the exact DC is given in the
creature&rsquo;s
descriptive text). A successful saving throw negates the effect. A
monster&rsquo;s gaze attack is described in abbreviated form in its
description. Each opponent within range of a gaze attack must attempt a
saving throw each round at the beginning of his or her turn in the
initiative order. Only looking directly at a creature with a gaze
attack leaves an opponent vulnerable. Opponents can avoid the need to
make the saving throw by not looking at the creature, in one of two
ways. </p>
    <p><i>Averting Eyes</i>: The opponent avoids looking at the
creature&rsquo;s face, instead looking at its body, watching its
shadow,
tracking it in a reflective surface, and so on. Each round, the
opponent has a 50% chance to not need to make a saving throw against
the gaze attack. The creature with the gaze attack, however, gains
concealment against that opponent.</p>
    <p><i>Wearing a Blindfold</i>: The opponent cannot see the
creature at all (also possible to achieve by turning one&rsquo;s back
on the
creature or shutting one&rsquo;s eyes). The creature with the gaze
attack
gains total concealment against the opponent. </p>
    <p>A creature with a gaze attack can actively gaze as an attack
action by choosing a target within range. That opponent must attempt a
saving throw but can try to avoid this as described above. Thus, it is
possible for an opponent to save against a creature&rsquo;s gaze twice
during
the same round, once before the opponent&rsquo;s action and once during
the
creature&rsquo;s turn. </p>
    <p>Gaze attacks can affect ethereal opponents. A creature is
immune to gaze attacks of others of its kind unless otherwise noted.</p>
    <p>Allies of a creature with a gaze attack might be affected. All
the creature&rsquo;s allies are considered to be averting their eyes
from the
creature with the gaze attack, and have a 50% chance to not need to
make a saving throw against the gaze attack each round. The creature
also can veil its eyes, thus negating its gaze ability.</p>
Also see <a href="abilitiesAndConditions.html#gaze-attacks" style={{
      "color": "rgb(87, 158, 182)"
    }}>Gaze Attacks</a> under Special
Abilities.
    <p><b><a id="giant"></a>Giant Type</b>: A giant is a
humanoid-shaped creature of
great strength, usually of at least Large size.</p>
    <p><i>Features</i>: A giant has the following features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Fortitude saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A giant possesses the following traits (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Low-light vision.</p>
      </li>
      <li>
        <p>Proficient with all simple and martial weapons, as well as
any natural weapons.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium or
heavy) it is described as wearing, as well as all lighter types. Giants
not described as wearing armor are not proficient with armor. Giants
are proficient with shields if they are proficient with any form of
armor.</p>
      </li>
      <li>
        <p>Giants eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="goblinoid"></a>Goblinoid Subtype</b>: Goblinoids
are stealthy humanoids
who live by hunting and raiding and who all speak Goblin.</p>
    <p><b><a id="good"></a>Good Subtype</b>: A subtype usually
applied only to
outsiders native to the good-aligned Outer Planes. Most creatures that
have this subtype also have good alignments; however, if their
alignments change, they still retain the subtype. Any effect that
depends on alignment affects a creature with this subtype as if the
creature has a good alignment, no matter what its alignment actually
is. The creature also suffers effects according to its actual
alignment. A creature with the good subtype overcomes damage reduction
as if its natural weapons and any weapons it wields were good-aligned
(see Damage Reduction, above).</p>
    <p><b><a id="humanoid"></a>Humanoid Type</b>: A humanoid
usually has two arms, two
legs, and one head, or a humanlike torso, arms, and a head. Humanoids
have few or no supernatural or extraordinary abilities, but most can
speak and usually have well-developed societies. They usually are Small
or Medium. Every humanoid creature also has a subtype.</p>
    <p>Humanoids with 1 Hit Die exchange the features of their
humanoid Hit Die for the class features of a PC or NPC class. Humanoids
of this sort are presented as 1st-level warriors, which means that they
have average combat ability and poor saving throws.</p>
    <p>Humanoids with more than 1 Hit Die are the only humanoids who
make use of the features of the humanoid type.</p>
    <p><i>Features</i>: A humanoid has the following features (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>8-sided Hit Dice, or by character class.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Reflex saves (usually; a humanoid&rsquo;s good save
varies).</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, or by character
class.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A humanoid possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Proficient with all simple weapons, or by character class.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) it is described as wearing, or by character class. If a humanoid
does not have a class and wears armor, it is proficient with that type
of armor and all lighter types. Humanoids not indicated as wearing
armor are not proficient with armor. Humanoids are proficient with
shields if they are proficient with any form of armor.</p>
      </li>
      <li>
        <p>Humanoids breathe, eat, and sleep.</p>
      </li>
    </ul>
    <p><b><a id="improved-grab"></a>Improved Grab (Ex)</b>: If a
creature with this special
attack hits with a melee weapon (usually a claw or bite attack), it
deals normal damage and attempts to start a grapple as a free action
without provoking an attack of opportunity. No initial touch attack is
required. Unless otherwise noted, improved grab works only against
opponents at least one size category smaller than the creature. The
creature has the option to conduct the grapple normally, or simply use
the part of its body it used in the improved grab to hold the opponent.
If it chooses to do the latter, it takes a &ndash;20 penalty on grapple
checks, but is not considered grappled itself; the creature does not
lose its Dexterity bonus to AC, still threatens an area, and can use
its remaining attacks against other opponents. A successful hold does
not deal any extra damage unless the creature also has the constrict
special attack. If the creature does not constrict, each successful
grapple check it makes during successive rounds automatically deals the
damage indicated for the attack that established the hold. Otherwise,
it deals constriction damage as well (the amount is given in the
creature&rsquo;s descriptive text). When a creature gets a hold after
an
improved grab attack, it pulls the opponent into its space. This act
does not provoke attacks of opportunity. It can even move (possibly
carrying away the opponent), provided it can drag the opponent&rsquo;s
weight. </p>
    <p><b><a id="incorporeal"></a>Incorporeal Subtype</b>: An
incorporeal creature has no
physical body. It can be harmed only by other incorporeal creatures,
magic weapons or creatures that strike as magic weapons, and spells,
spell-like abilities, or supernatural abilities. It is immune to all
nonmagical attack forms. Even when hit by spells or magic weapons, it
has a 50% chance to ignore any damage from a corporeal source (except
for positive energy, negative energy, force effects such as magic
missile, or attacks made with ghost touch weapons). Although it is not
a magical attack, holy water can affect incorporeal undead, but a hit
with holy water has a 50% chance of not affecting an incorporeal
creature.</p>
    <p>An incorporeal creature has no natural armor bonus but has a
deflection bonus equal to its Charisma bonus (always at least +1, even
if the creature&rsquo;s Charisma score does not normally provide a
bonus). </p>
    <p>An incorporeal creature can enter or pass through solid
objects, but must remain adjacent to the object&rsquo;s exterior, and
so
cannot pass entirely through an object whose space is larger than its
own. It can sense the presence of creatures or objects within a square
adjacent to its current location, but enemies have total concealment
(50% miss chance) from an incorporeal creature that is inside an
object. In order to see farther from the object it is in and attack
normally, the incorporeal creature must emerge. An incorporeal creature
inside an object has total cover, but when it attacks a creature
outside the object it only has cover, so a creature outside with a
readied action could strike at it as it attacks. An incorporeal
creature cannot pass through a force effect.</p>
    <p>An incorporeal creature&rsquo;s attacks pass through (ignore)
natural armor, armor, and shields, although deflection bonuses and
force effects (such as mage armor) work normally against it.
Incorporeal creatures pass through and operate in water as easily as
they do in air. Incorporeal creatures cannot fall or take falling
damage. Incorporeal creatures cannot make trip or grapple attacks, nor
can they be tripped or grappled. In fact, they cannot take any physical
action that would move or manipulate an opponent or its equipment, nor
are they subject to such actions. Incorporeal creatures have no weight
and do not set off traps that are triggered by weight.</p>
    <p>An incorporeal creature moves silently and cannot be heard
with Listen checks if it doesn&rsquo;t wish to be. It has no Strength
score,
so its Dexterity modifier applies to both its melee attacks and its
ranged attacks. Nonvisual senses, such as scent and blindsight, are
either ineffective or only partly effective with regard to incorporeal
creatures. Incorporeal creatures have an innate sense of direction and
can move at full speed even when they cannot see.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#incorporeality" style={{
        "color": "rgb(87, 158, 182)"
      }}>Incorporeality</a> under Special
Abilities.</p>
    <p><b><a id="lawful"></a>Lawful</b>: A subtype usually applied
only to outsiders
native to the lawful-aligned Outer Planes. Most creatures that have
this subtype also have lawful alignments; however, if their alignments
change, they still retain the subtype. Any effect that depends on
alignment affects a creature with this subtype as if the creature has a
lawful alignment, no matter what its alignment actually is. The
creature also suffers effects according to its actual alignment. A
creature with the lawful subtype overcomes damage reduction as if its
natural weapons and any weapons it wields were lawful-aligned (see
Damage Reduction, above).</p>
    <p><b><a id="low-light-vision"></a>Low-Light Vision (Ex)</b>: A
creature with low-light vision
can see twice as far as a human in starlight, moonlight, torchlight,
and similar conditions of shadowy illumination. It retains the ability
to distinguish color and detail under these conditions.</p>
    <p><b><a id="magical-beast"></a>Magical Beast Type</b>: Magical
beasts are similar to
animals but can have Intelligence scores higher than 2. Magical beasts
usually have supernatural or extraordinary abilities, but sometimes are
merely bizarre in appearance or habits.</p>
    <p><i>Features</i>: A magical beast has the following features. </p>
    <ul>
      <li>
        <p>10-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to total Hit Dice (as fighter).</p>
      </li>
      <li>
        <p>Good Fortitude and Reflex saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A magical beast possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet and low-light vision.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons only.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Magical beasts eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="manufactured-weapons"></a>Manufactured Weapons</b>:
Some monsters employ manufactured
weapons when they attack. Creatures that use swords, bows, spears, and
the like follow the same rules as characters, including those for
additional attacks from a high base attack bonus and two-weapon
fighting penalties. This category also includes &ldquo;found
items,&rdquo; such as
rocks and logs, that a creature wields in combat&mdash; in essence, any
weapon that is not intrinsic to the creature.</p>
    <p>Some creatures combine attacks with natural and manufactured
weapons when they make a full attack. When they do so, the manufactured
weapon attack is considered the primary attack unless the
creature&rsquo;s
description indicates otherwise and any natural weapons the creature
also uses are considered secondary natural attacks. These secondary
attacks do not interfere with the primary attack as attacking with an
off-hand weapon does, but they take the usual &ndash;5 penalty (or
&ndash;2 with
the Multiattack feat) for such attacks, even if the natural weapon used
is normally the creature&rsquo;s primary natural weapon.</p>
    <p><b><a id="monstrous-humanoid"></a>Monstrous Humanoid Type</b>:
Monstrous humanoids are
similar to humanoids, but with monstrous or animalistic features. They
often have magical abilities as well.</p>
    <p><i>Features</i>: A monstrous humanoid has the following
features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to total Hit Dice (as fighter).</p>
      </li>
      <li>
        <p>Good Reflex and Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: A monstrous humanoid possesses the following
traits (unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Proficient with all simple weapons and any weapons
mentioned
in its entry.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) it is described as wearing, as well as all lighter types.
Monstrous humanoids not indicated as wearing armor are not proficient
with armor. Monstrous humanoids are proficient with shields if they are
proficient with any form of armor.</p>
      </li>
      <li>
        <p>Monstrous humanoids eat, sleep, and breathe.</p>
      </li>
    </ul>
    <p><b><a id="movement-modes"></a>Movement Modes</b>: Creatures
may have modes of movement
other than walking and running. These are natural, not magical, unless
specifically noted in a monster description.</p>
    <p><i>Burrow</i>: A creature with a burrow speed can tunnel
through dirt, but not through rock unless the descriptive text says
otherwise. Creatures cannot charge or run while burrowing. Most
burrowing creatures do not leave behind tunnels other creatures can use
(either because the material they tunnel through fills in behind them
or because they do not actually dislocate any material when burrowing);
see the individual creature descriptions for details.</p>
    <p><i>Climb</i>: A creature with a climb speed has a +8 racial
bonus on all <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a> checks. The creature must
make a Climb check to climb any wall or slope with a DC of more than 0,
but it always can choose to take 10 even if rushed or threatened while
climbing. The creature climbs at the given speed while climbing. If it
chooses an accelerated climb it moves at double the given climb speed
(or its base land speed, whichever is lower) and makes a single Climb
check at a &ndash;5 penalty. Creatures cannot run while climbing. A
creature
retains its Dexterity bonus to Armor Class (if any) while climbing, and
opponents get no special bonus on their attacks against a climbing
creature.</p>
    <p><i>Fly</i>: A creature with a fly speed can move through the
air at the indicated speed if carrying no more than a light load. (Note
that medium armor does not necessarily constitute a medium load.) All
fly speeds include a parenthetical note indicating maneuverability, as
follows: </p>
    <ul>
      <li>
        <p>Perfect: The creature can perform almost any aerial
maneuver
it wishes. It moves through the air as well as a human moves over
smooth ground.</p>
      </li>
      <li>
        <p>Good: The creature is very agile in the air (like a
housefly
or a hummingbird), but cannot change direction as readily as those with
perfect maneuverability.</p>
      </li>
      <li>
        <p>Average: The creature can fly as adroitly as a small bird.</p>
      </li>
      <li>
        <p>Poor: The creature flies as well as a very large bird.</p>
      </li>
      <li>
        <p>Clumsy: The creature can barely maneuver at all.</p>
      </li>
    </ul>
    <p> </p>
    <p>A creature that flies can make dive attacks. A dive attack
works just like a charge, but the diving creature must move a minimum
of 30 feet and descend at least 10 feet. It can make only claw or talon
attacks, but these deal double damage. A creature can use the run
action while flying, provided it flies in a straight line. </p>
    <p><i>Swim</i>: A creature with a swim speed can move through
water at its swim speed without making Swim checks. It has a +8 racial
bonus on any Swim check to perform some special action or avoid a
hazard. The creature can always can choose to take 10 on a Swim check,
even if distracted or endangered. The creature can use the run action
while swimming, provided it swims in a straight line. </p>
    <p><b><a id="native"></a>Native Subtype</b>: A subtype applied
only to outsiders.
These creatures have mortal ancestors or a strong connection to the
Material Plane and can be raised, reincarnated, or resurrected just as
other living creatures can be. Creatures with this subtype are native
to the Material Plane (hence the subtype&rsquo;s name). Unlike true
outsiders, native outsiders need to eat and sleep. </p>
    <p><b><a id="natural-weapons"></a>Natural Weapons</b>: Natural
weapons are weapons that are
physically a part of a creature. A creature making a melee attack with
a natural weapon is considered armed and does not provoke attacks of
opportunity. Likewise, it threatens any space it can reach. Creatures
do not receive additional attacks from a high base attack bonus when
using natural weapons. The number of attacks a creature can make with
its natural weapons depends on the type of the attack&mdash;generally,
a
creature can make one bite attack, one attack per claw or tentacle, one
gore attack, one sting attack, or one slam attack (although Large
creatures with arms or arm-like limbs can make a slam attack with each
arm). Refer to the individual monster descriptions.</p>
    <p>Unless otherwise noted, a natural weapon threatens a critical
hit on a natural attack roll of 20.</p>
    <p>When a creature has more than one natural weapon, one of them
(or sometimes a pair or set of them) is the primary weapon. All the
creature&rsquo;s remaining natural weapons are secondary. </p>
    <p>The primary weapon is given in the creature&rsquo;s Attack
entry,
and the primary weapon or weapons is given first in the
creature&rsquo;s Full
Attack entry. A creature&rsquo;s primary natural weapon is its most
effective
natural attack, usually by virtue of the creature&rsquo;s physiology,
training, or innate talent with the weapon. An attack with a primary
natural weapon uses the creature&rsquo;s full attack bonus. Attacks
with
secondary natural weapons are less effective and are made with a
&ndash;5
penalty on the attack roll, no matter how many there are. (Creatures
with the Multiattack feat take only a &ndash;2 penalty on secondary
attacks.)
This penalty applies even when the creature makes a single attack with
the secondary weapon as part of the attack action or as an attack of
opportunity.</p>
    <p>Natural weapons have types just as other weapons do. The most
common are summarized below.</p>
    <p><i>Bite</i>: The creature attacks with its mouth, dealing
piercing, slashing, and bludgeoning damage.</p>
    <p><i>Claw or Talon</i>: The creature rips with a sharp
appendage, dealing piercing and slashing damage.</p>
    <p><i>Gore</i>: The creature spears the opponent with an antler,
horn, or similar appendage, dealing piercing damage.</p>
    <p><i>Slap or Slam</i>: The creature batters opponents with an
appendage, dealing bludgeoning damage.</p>
    <p><i>Sting</i>: The creature stabs with a stinger, dealing
piercing damage. Sting attacks usually deal damage from poison in
addition to hit point damage.</p>
    <p><i>Tentacle</i>: The creature flails at opponents with a
powerful tentacle, dealing bludgeoning (and sometimes slashing) damage.
    </p>
    <p><b><a id="nonabilities"></a>Nonabilities</b>: Some creatures
lack certain ability
scores. These creatures do not have an ability score of 0&mdash;they
lack the
ability altogether. The modifier for a nonability is +0. Other effects
of nonabilities are detailed below.</p>
    <p><i>Strength</i>: Any creature that can physically manipulate
other objects has at least 1 point of Strength. A creature with no
Strength score can&rsquo;t exert force, usually because it has no
physical
body or because it doesn&rsquo;t move. The creature automatically fails
Strength checks. If the creature can attack, it applies its Dexterity
modifier to its base attack bonus instead of a Strength modifier.</p>
    <p><i>Dexterity</i>: Any creature that can move has at least 1
point of Dexterity. A creature with no Dexterity score can&rsquo;t
move. If
it can perform actions (such as casting spells), it applies its
Intelligence modifier to initiative checks instead of a Dexterity
modifier. The creature automatically fails Reflex saves and Dexterity
checks.</p>
    <p><i>Constitution</i>: Any living creature has at least 1 point
of Constitution. A creature with no Constitution has no body or no
metabolism. It is immune to any effect that requires a Fortitude save
unless the effect works on objects or is harmless. The creature is also
immune to ability damage, ability drain, and energy drain, and
automatically fails Constitution checks. A creature with no
Constitution cannot tire and thus can run indefinitely without tiring
(unless the creature&rsquo;s description says it cannot run).</p>
    <p><i>Intelligence</i>: Any creature that can think, learn, or
remember has at least 1 point of Intelligence. A creature with no
Intelligence score is mindless, an automaton operating on simple
instincts or programmed instructions. It has immunity to mind-affecting
effects (charms, compulsions, phantasms, patterns, and morale effects)
and automatically fails Intelligence checks.</p>
    <p>Mindless creatures do not gain feats or skills, although they
may have bonus feats or racial skill bonuses.</p>
    <p><i>Wisdom</i>: Any creature that can perceive its environment
in any fashion has at least 1 point of Wisdom. Anything with no Wisdom
score is an object, not a creature. Anything without a Wisdom score
also has no Charisma score.</p>
    <p><i>Charisma</i>: Any creature capable of telling the
difference between itself and things that are not itself has at least 1
point of Charisma. Anything with no Charisma score is an object, not a
creature. Anything without a Charisma score also has no Wisdom score.</p>
    <p><b><a id="ooze"></a>Ooze Type</b>: An ooze is an amorphous
or mutable creature,
usually mindless.</p>
    <p><i>Features</i>: An ooze has the following features. </p>
    <ul>
      <li>
        <p>10-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>No good saving throws.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, if the ooze has
an Intelligence score. However, most oozes are mindless and gain no
skill points or feats.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An ooze possesses the following traits (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Mindless: No Intelligence score, and immunity to all
mind-affecting effects (charms, compulsions, phantasms, patterns, and
morale effects).</p>
      </li>
      <li>
        <p>Blind (but have the blindsight special quality), with
immunity to gaze attacks, visual effects, illusions, and other attack
forms that rely on sight.</p>
      </li>
      <li>
        <p>Immunity to poison, sleep effects, paralysis, polymorph,
and
stunning.</p>
      </li>
      <li>
        <p>Some oozes have the ability to deal acid damage to
objects.
In such a case, the amount of damage is equal to 10 + 1/2 ooze&rsquo;s
HD +
ooze&rsquo;s Con modifier per full round of contact.</p>
      </li>
      <li>
        <p>Not subject to critical hits or flanking.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons only.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Oozes eat and breathe, but do not sleep.</p>
      </li>
    </ul>
    <p><b><a id="outsider"></a>Outsider Type</b>: An outsider is at
least partially
composed of the essence (but not necessarily the material) of some
plane other than the Material Plane. Some creatures start out as some
other type and become outsiders when they attain a higher (or lower)
state of spiritual existence.</p>
    <p><i>Features</i>: An outsider has the following features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to total Hit Dice (as fighter).</p>
      </li>
      <li>
        <p>Good Fortitude, Reflex, and Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (8 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An outsider possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Unlike most other living creatures, an outsider does not
have
a dual nature&mdash;its soul and body form one unit. When an outsider
is
slain, no soul is set loose. Spells that restore souls to their bodies,
such as raise dead, reincarnate, and resurrection, don&rsquo;t work on
an
outsider. It takes a different magical effect, such as limited wish,
wish, miracle, or true resurrection to restore it to life. An outsider
with the native subtype can be raised, reincarnated, or resurrected
just as other living creatures can be.</p>
      </li>
      <li>
        <p>Proficient with all simple and martial weapons and any
weapons mentioned in its entry.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) it is described as wearing, as well as all lighter types.
Outsiders not indicated as wearing armor are not proficient with armor.
Outsiders are proficient with shields if they are proficient with any
form of armor.</p>
      </li>
      <li>
        <p>Outsiders breathe, but do not need to eat or sleep
(although
they can do so if they wish). Native outsiders breathe, eat, and sleep.
        </p>
      </li>
    </ul>
    <p><b><a id="paralysis"></a>Paralysis (Ex or Su)</b>: This
special attack renders the
victim immobile. Paralyzed creatures cannot move, speak, or take any
physical actions. The creature is rooted to the spot, frozen and
helpless. Paralysis works on the body, and a character can usually
resist it with a Fortitude saving throw (the DC is given in the
creature&rsquo;s description). Unlike hold person and similar effects,
a
paralysis effect does not allow a new save each round. A winged
creature flying in the air at the time that it is paralyzed cannot flap
its wings and falls. A swimmer can&rsquo;t swim and may drown.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#paralysis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Paralysis</a> under Special
Abilities. </p>
    <p><b><a id="plant"></a>Plant Type</b>: This type comprises
vegetable creatures.
Note that regular plants, such as one finds growing in gardens and
fields, lack Wisdom and Charisma scores (see Nonabilities, above) and
are not creatures, but objects, even though they are alive. </p>
    <p><i>Features</i>: A plant creature has the following features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Fortitude saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, if the plant
creature has an Intelligence score. However, some plant creatures are
mindless and gain no skill points or feats.</p>
      </li>
    </ul>
    <p> </p>
    <p><i>Traits</i>: A plant creature possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Low-light vision.</p>
      </li>
      <li>
        <p>Immunity to all mind-affecting effects (charms,
compulsions,
phantasms, patterns, and morale effects).</p>
      </li>
      <li>
        <p>Immunity to poison, sleep effects, paralysis, polymorph,
and
stunning.</p>
      </li>
      <li>
        <p>Not subject to critical hits.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons only.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Plants breathe and eat, but do not sleep.</p>
      </li>
    </ul>
    <p><b><a id="poison"></a>Poison (Ex)</b>: Poison attacks deal
initial damage, such
as ability damage (see page 305) or some other effect, to the opponent
on a failed Fortitude save. Unless otherwise noted, another saving
throw is required 1 minute later (regardless of the first save&rsquo;s
result) to avoid secondary damage. A creature&rsquo;s descriptive text
provides the details.</p>
    <p>A creature with a poison attack is immune to its own poison
and the poison of others of its kind.</p>
    <p>The Fortitude save DC against a poison attack is equal to 10 +
1/2 poisoning creature&rsquo;s racial HD + poisoning creature&rsquo;s
Con modifier
(the exact DC is given in the creature&rsquo;s descriptive text).</p>
    <p>A successful save avoids (negates) the damage.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#poison" style={{
        "color": "rgb(87, 158, 182)"
      }}>Poison</a> under Special Abilities.</p>
    <p><b><a id="pounce"></a>Pounce (Ex)</b>: When a creature with
this special attack
makes a charge, it can follow with a full attack&mdash;including rake
attacks
if the creature also has the rake ability.</p>
    <p><b><a id="powerful-charge"></a>Powerful Charge (Ex)</b>:
When a creature with this special
attack makes a charge, its attack deals extra damage in addition to the
normal benefits and hazards of a charge. The amount of damage from the
attack is given in the creature&rsquo;s description.</p>
    <p><b><a id="psionics"></a>Psionics (Sp)</b>: These are
spell-like abilities that a
creature generates with the power of its mind. Psionic abilities are
usually usable at will.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionics</a> under Special Abilities
or the <a href="psionic.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionics
Section</a>.</p>
    <p><b><a id="rake"></a>Rake (Ex)</b>: A creature with this
special attack gains
extra natural attacks when it grapples its foe. Normally, a monster can
attack with only one of its natural weapons while grappling, but a
monster with the rake ability usually gains two additional claw attacks
that it can use only against a grappled foe. Rake attacks are not
subject to the usual &ndash;4 penalty for attacking with a natural
weapon in
a grapple.</p>
    <p>A monster with the rake ability must begin its turn grappling
to use its rake&mdash;it can&rsquo;t begin a grapple and rake in the
same turn.</p>
    <p><b><a id="ray"></a>Ray (Su or Sp)</b>: This form of special
attack works like
a ranged attack. Hitting with a ray attack requires a successful ranged
touch attack roll, ignoring armor, natural armor, and shield and using
the creature&rsquo;s ranged attack bonus. Ray attacks have no range
increment. The creature&rsquo;s descriptive text specifies the maximum
range,
effects, and any applicable saving throw.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#rays" style={{
        "color": "rgb(87, 158, 182)"
      }}>Rays</a> under Special Abilities.</p>
    <p><b><a id="regeneration"></a>Regeneration (Ex)</b>: A
creature with this ability is
difficult to kill. Damage dealt to the creature is treated as nonlethal
damage. The creature automatically heals nonlethal damage at a fixed
rate per round, as given in the entry. Certain attack forms, typically
fire and acid, deal lethal damage to the creature, which doesn&rsquo;t
go
away. The creature&rsquo;s descriptive text describes the details. A
regenerating creature that has been rendered unconscious through
nonlethal damage can be killed with a coup de grace. The attack cannot
be of a type that automatically converts to nonlethal damage.</p>
    <p>Attack forms that don&rsquo;t deal hit point damage ignore
regeneration. Regeneration also does not restore hit points lost from
starvation, thirst, or suffocation. Regenerating creatures can regrow
lost portions of their bodies and can reattach severed limbs or body
parts; details are in the creature&rsquo;s descriptive text. Severed
parts
that are not reattached wither and die normally.</p>
    <p>A creature must have a Constitution score to have the
regeneration ability.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#regeneration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Regeneration</a> under Special
Abilities.</p>
    <p><b><a id="reptilian"></a>Reptilian Subtype</b>: These
creatures are scaly and
usually coldblooded. The reptilian subtype is only used to describe a
set of humanoid races, not all animals and monsters that are truly
reptiles.</p>
    <p><b><a id="resistance-to-energy"></a>Resistance to Energy (Ex)</b>:
A creature with this special
quality ignores some damage of the indicated type each time it takes
damage of that kind (commonly acid, cold, fire, or electricity). The
entry indicates the amount and type of damage ignored.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Resistance to Energy</a> under
Special Abilities.</p>
    <p><b><a id="scent"></a>Scent (Ex)</b>: This special quality
allows a creature to
detect approaching enemies, sniff out hidden foes, and track by sense
of smell. Creatures with the scent ability can identify familiar odors
just as humans do familiar sights.</p>
    <p>The creature can detect opponents within 30 feet by sense of
smell. If the opponent is upwind, the range increases to 60 feet; if
downwind, it drops to 15 feet. Strong scents, such as smoke or rotting
garbage, can be detected at twice the ranges noted above. Overpowering
scents, such as skunk musk or troglodyte stench, can be detected at
triple normal range.</p>
    <p>When a creature detects a scent, the exact location of the
source is not revealed&mdash;only its presence somewhere within range.
The
creature can take a move action to note the direction of the scent.</p>
    <p>Whenever the creature comes within 5 feet of the source, the
creature pinpoints the source&rsquo;s location.</p>
    <p>A creature with the Track feat and the scent ability can
follow tracks by smell, making a Wisdom (or Survival) check to find or
follow a track. The typical DC for a fresh trail is 10 (no matter what
kind of surface holds the scent). This DC increases or decreases
depending on how strong the quarry&rsquo;s odor is, the number of
creatures,
and the age of the trail. For each hour that the trail is cold, the DC
increases by 2. The ability otherwise follows the rules for the Track
feat. Creatures tracking by scent ignore the effects of surface
conditions and poor visibility.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>Scent</a> under Special Abilities. </p>
    <p><b><a id="shapechanger"></a>Shapechanger Subtype</b>: A
shapechanger has the
supernatural ability to assume one or more alternate forms. Many
magical effects allow some kind of shape shifting, and not every
creature that can change shapes has the shapechanger subtype. </p>
    <p><i>Traits</i>: A shapechanger possesses the following traits
(unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Proficient with its natural weapons, with simple weapons,
and
with any weapons mentioned in the creature&rsquo;s description.</p>
      </li>
      <li>
        <p>Proficient with any armor mentioned in the
creature&rsquo;s
description, as well as all lighter forms. If no form of armor is
mentioned, the shapechanger is not proficient with armor. A
shapechanger is proficient with shields if it is proficient with any
type of armor.</p>
      </li>
    </ul>
    <p><b><a id="sonic-attacks"></a>Sonic Attacks (Su)</b>: Unless
otherwise noted, a sonic
attack follows the rules for spreads. The range of the spread is
measured from the creature using the sonic attack. Once a sonic attack
has taken effect, deafening the subject or stopping its ears does not
end the effect. Stopping one&rsquo;s ears ahead of time allows
opponents to
avoid having to make saving throws against mind-affecting sonic
attacks, but not other kinds of sonic attacks (such as those that deal
damage). Stopping one&rsquo;s ears is a full-round action and requires
wax or
other soundproof material to stuff into the ears.</p>
    <p><b><a id="special-abilities"></a>Special Abilities</b>: A
special ability is either
extraordinary (Ex), spell-like (Sp), or supernatural (Su).</p>
    <p><i>Extraordinary</i>: Extraordinary abilities are nonmagical,
don&rsquo;t become ineffective in an antimagic field, and are not
subject to
any effect that disrupts magic. Using an extraordinary ability is a
free action unless otherwise noted.</p>
    <p><i>Spell-Like</i>: Spell-like abilities are magical and work
just like spells (though they are not spells and so have no verbal,
somatic, material, focus, or XP components). They go away in an
antimagic field and are subject to spell resistance if the spell the
ability resembles or duplicates would be subject to spell resistance.</p>
    <p>A spell-like ability usually has a limit on how often it can
be used. A spell-like ability that can be used at will has no use
limit. Using a spell-like ability is a standard action unless noted
otherwise, and doing so while threatened provokes attacks of
opportunity. It is possible to make a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
check to use a
spell-like ability defensively and avoid provoking an attack of
opportunity, just as when casting a spell. A spell-like ability can be
disrupted just as a spell can be. Spell-like abilities cannot be used
to counterspell, nor can they be counterspelled.</p>
    <p>For creatures with spell-like abilities, a designated caster
level defines how difficult it is to dispel their spell-like effects
and to define any level-dependent variables (such as range and
duration) the abilities might have. The creature&rsquo;s caster level
never
affects which spell-like abilities the creature has; sometimes the
given caster level is lower than the level a spellcasting character
would need to cast the spell of the same name. If no caster level is
specified, the caster level is equal to the creature&rsquo;s Hit Dice.
The
saving throw (if any) against a spell-like ability is 10 + the level of
the spell the ability resembles or duplicates + the creature&rsquo;s
Cha
modifier.</p>
    <p>Some spell-like abilities duplicate spells that work
differently when cast by characters of different classes. A
monster&rsquo;s
spell-like abilities are presumed to be the sorcerer/wizard versions.
If the spell in question is not a sorcerer/wizard spell, then default
to cleric, druid, bard, paladin, and ranger, in that order.</p>
    <p><i>Supernatural</i>: Supernatural abilities are magical and go
away in an antimagic field but are not subject to spell resistance.
Supernatural abilities cannot be dispelled. Using a supernatural
ability is a standard action unless noted otherwise. Supernatural
abilities may have a use limit or be usable at will, just like
spell-like abilities. However, supernatural abilities do not provoke
attacks of opportunity and never require Concentration checks. Unless
otherwise noted, a supernatural ability has an effective caster level
equal to the creature&rsquo;s Hit Dice. The saving throw (if any)
against a
supernatural ability is 10 + 1/2 the creature&rsquo;s HD + the
creature&rsquo;s
ability modifier (usually Charisma).</p>
    <p><b><a id="spell-immunity"></a>Spell Immunity (Ex)</b>: A
creature with spell immunity
avoids the effects of spells and spell-like abilities that directly
affect it. This works exactly like spell resistance, except that it
cannot be overcome. Sometimes spell immunity is conditional or applies
to only spells of a certain kind or level. Spells that do not allow
spell resistance are not affected by spell immunity.</p>
    <p><b><a id="SR"></a><a id="spell-resistance"></a>Spell Resistance (Ex)</b>: A creature with
spell resistance
can avoid the effects of spells and spell-like abilities that directly
affect it.To determine if a spell or spell-like ability works against a
creature with spell resistance, the caster must make a caster level
check (1d20 + caster level). If the result equals or exceeds the
creature&rsquo;s spell resistance, the spell works normally, although
the
creature is still allowed a saving throw.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#spell-resistance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spell Resistance</a> under Special
Abilities.</p>
    <p><b><a id="spells"></a>Spells</b>: Sometimes a creature can
cast arcane or divine
spells just as a member of a spellcasting class can (and can activate
magic items accordingly). Such creatures are subject to the same
spellcasting rules that characters are, except as follows. </p>
    <p>A spellcasting creature that lacks hands or arms can provide
any somatic component a spell might require by moving its body. Such a
creature also does need material components for its spells. The
creature can cast the spell by either touching the required component
(but not if the component is in another creature&rsquo;s possession) or
having the required component on its person. Sometimes spellcasting
creatures utilize the Eschew Materials feat to avoid fussing with
noncostly components.</p>
    <p>A spellcasting creature is not actually a member of a class
unless its entry says so, and it does not gain any class abilities. A
creature with access to cleric spells must prepare them in the normal
manner and receives domain spells if noted, but it does not receive
domain granted powers unless it has at least one level in the cleric
class.</p>
    <p><b><a id="summon"></a>Summon (Sp)</b>: A creature with the
summon ability can
summon specific other creatures of its kind much as though casting a
summon monster spell, but it usually has only a limited chance of
success (as specified in the creature&rsquo;s entry). Roll d%: On a
failure,
no creature answers the summons. Summoned creatures automatically
return whence they came after 1 hour. A creature that has just been
summoned cannot use its own summon ability for 1 hour. Most creatures
with the ability to summon do not use it lightly, since it leaves them
beholden to the summoned creature. In general, they use it only when
necessary to save their own lives. An appropriate spell level is given
for each summoning ability for purposes of <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a>
checks and
attempts to dispel the summoned creature. No experience points are
awarded for summoned monsters.</p>
    <p><b><a id="swallow-whole"></a>Swallow Whole (Ex)</b>: If a
creature with this special
attack begins its turn with an opponent held in its mouth (see Improved
Grab), it can attempt a new grapple check (as though attempting to pin
the opponent). If it succeeds, it swallows its prey, and the opponent
takes bite damage. Unless otherwise noted, the opponent can be up to
one size category smaller than the swallowing creature. Being swallowed
has various consequences, depending on the creature doing the
swallowing. A swallowed creature is considered to be grappled, while
the creature that did the swallowing is not. A swallowed creature can
try to cut its way free with any light slashing or piercing weapon (the
amount of cutting damage required to get free is noted in the creature
description), or it can just try to escape the grapple. The Armor Class
of the interior of a creature that swallows whole is normally 10 + 1/2
its natural armor bonus, with no modifiers for size or Dexterity. If
the swallowed creature escapes the grapple, success puts it back in the
attacker&rsquo;s mouth, where it may be bitten or swallowed again.</p>
    <p><b><a id="swarm"></a>Swarm Subtype</b>: A swarm is a
collection of Fine,
Diminutive, or Tiny creatures that acts as a single creature. A swarm
has the characteristics of its type, except as noted here. A swarm has
a single pool of Hit Dice and hit points, a single initiative modifier,
a single speed, and a single Armor Class. A swarm makes saving throws
as a single creature. A single swarm occupies a square (if it is made
up of nonflying creatures) or a cube (of flying creatures) 10 feet on a
side, but its reach is 0 feet, like its component creatures. In order
to attack, it moves into an opponent&rsquo;s space, which provokes an
attack
of opportunity. It can occupy the same space as a creature of any size,
since it crawls all over its prey. A swarm can move through squares
occupied by enemies and vice versa without impediment, although the
swarm provokes an attack of opportunity if it does so. A swarm can move
through cracks or holes large enough for its component creatures.</p>
    <p>A swarm of Tiny creatures consists of 300 nonflying creatures
or 1,000 flying creatures. A swarm of Diminutive creatures consists of
1,500 nonflying creatures or 5,000 flying creatures. A swarm of Fine
creatures consists of 10,000 creatures, whether they are flying or not.
Swarms of nonflying creatures include many more creatures than could
normally fit in a 10-foot square based on their normal space, because
creatures in a swarm are packed tightly together and generally crawl
over each other and their prey when moving or attacking. Larger swarms
are represented by multiples of single swarms. The area occupied by a
large swarm is completely shapeable, though the swarm usually remains
in contiguous squares.</p>
    <p><i>Traits</i>: A swarm has no clear front or back and no
discernable anatomy, so it is not subject to critical hits or flanking.
A swarm made up of Tiny creatures takes half damage from slashing and
piercing weapons. A swarm composed of Fine or Diminutive creatures is
immune to all weapon damage. Reducing a swarm to 0 hit points or lower
causes it to break up, though damage taken until that point does not
degrade its ability to attack or resist attack. Swarms are never
staggered or reduced to a dying state by damage. Also, they cannot be
tripped, grappled, or bull rushed, and they cannot grapple an opponent.</p>
    <p>A swarm is immune to any spell or effect that targets a
specific number of creatures (including single-target spells such as
disintegrate), with the exception of mind-affecting effects (charms,
compulsions, phantasms, patterns, and morale effects) if the swarm has
an Intelligence score and a hive mind. A swarm takes half again as much
damage (+50%) from spells or effects that affect an area, such as
splash weapons and many evocation spells.</p>
    <p>Swarms made up of Diminutive or Fine creatures are susceptible
to high winds such as that created by a gust of wind spell. For
purposes of determining the effects of wind on a swarm, treat the swarm
as a creature of the same size as its constituent creatures. A swarm
rendered unconscious by means of nonlethal damage becomes disorganized
and dispersed, and does not reform until its hit points exceed its
nonlethal damage.</p>
    <p><b><a id="swarm-attack"></a>Swarm Attack</b>: Creatures with
the swarm subtype don&rsquo;t
make standard melee attacks. Instead, they deal automatic damage to any
creature whose space they occupy at the end of their move, with no
attack roll needed. Swarm attacks are not subject to a miss chance for
concealment or cover. A swarm&rsquo;s statistics block has
&ldquo;swarm&rdquo; in the
Attack and Full Attack entries, with no attack bonus given. The amount
of damage a swarm deals is based on its Hit Dice, as shown below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "100px"
          }}>Swarm HD</th>
          <th style={{
            "width": "100px"
          }}>Swarm Base Damage</th>
        </tr>
        <tr className="odd-row">
          <td>1&ndash;5</td>
          <td>1d6</td>
        </tr>
        <tr>
          <td>6&ndash;10</td>
          <td>2d6</td>
        </tr>
        <tr className="odd-row">
          <td>11&ndash;15</td>
          <td>3d6</td>
        </tr>
        <tr>
          <td>16&ndash;20</td>
          <td>4d6</td>
        </tr>
        <tr className="odd-row">
          <td>21 or more</td>
          <td>5d6</td>
        </tr>
      </tbody>
    </table>
    <p>A swarm&rsquo;s attacks are nonmagical, unless the
swarm&rsquo;s
description states otherwise. Damage reduction sufficient to reduce a
swarm attack&rsquo;s damage to 0, being incorporeal, and other special
abilities usually give a creature immunity (or at least resistance) to
damage from a swarm. Some swarms also have acid, poison, blood drain,
or other special attacks in addition to normal damage.</p>
    <p>Swarms do not threaten creatures in their square, and do not
make attacks of opportunity with their swarm attack. However, they
distract foes whose squares they occupy, as described below.</p>
    <p><i>Distraction (Ex)</i>: Any living creature vulnerable to a
swarm&rsquo;s damage that begins its turn with a swarm in its square is
nauseated for 1 round; a Fortitude save (DC 10 + 1/2 swarm&rsquo;s HD +
swarm&rsquo;s Con modifier; the exact DC is given in a swarm&rsquo;s
description)
negates the effect. Spellcasting or concentrating on spells within the
area of a swarm requires a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 20 +
spell level).
Using skills that involve patience and concentration requires a DC 20
Concentration check.</p>
    <p><b><a id="telepathy"></a>Telepathy (Su)</b>: A creature with
this ability can
communicate telepathically with any other creature within a certain
range (specified in the creature&rsquo;s entry, usually 100 feet) that
has a
language. It is possible to address multiple creatures at once
telepathically, although maintaining a telepathic conversation with
more than one creature at a time is just as difficult as simultaneously
speaking and listening to multiple people at the same time.</p>
    <p>Some creatures have a limited form of telepathy, while others
have a more powerful form of the ability.</p>
    <p><b><a id="trample"></a>Trample (Ex)</b>: As a full-round
action, a creature with
this special attack can move up to twice its speed and literally run
over any opponents at least one size category smaller than itself. The
creature merely has to move over the opponents in its path; any
creature whose space is completely covered by the trampling
creature&rsquo;s
space is subject to the trample attack. If a target&rsquo;s space is
larger
than 5 feet, it is only considered trampled if the trampling creature
moves over all the squares it occupies. If the trampling creature moves
over only some of a target&rsquo;s space, the target can make an attack
of
opportunity against the trampling creature at a &ndash;4 penalty. A
trampling
creature that accidentally ends its movement in an illegal space
returns to the last legal position it occupied, or the closest legal
position, if there&rsquo;s a legal position that&rsquo;s closer.</p>
    <p>A trample attack deals bludgeoning damage (the
creature&rsquo;s slam
damage + 1-1/2 times its Str modifier). The creature&rsquo;s
descriptive text
gives the exact amount.</p>
    <p>Trampled opponents can attempt attacks of opportunity, but
these take a &ndash;4 penalty. If they do not make attacks of
opportunity,
trampled opponents can attempt Reflex saves to take half damage.</p>
    <p>The save DC against a creature&rsquo;s trample attack is 10 +
1/2
creature&rsquo;s HD + creature&rsquo;s Str modifier (the exact DC is
given in the
creature&rsquo;s descriptive text). A trampling creature can only deal
trampling damage to each target once per round, no matter how many
times its movement takes it over a target creature.</p>
    <p><b><a id="tremorsense"></a>Tremorsense (Ex)</b>: A creature
with tremorsense is
sensitive to vibrations in the ground and can automatically pinpoint
the location of anything that is in contact with the ground. Aquatic
creatures with tremorsense can also sense the location of creatures
moving through water. The ability&rsquo;s range is specified in the
creature&rsquo;s descriptive text.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#tremorsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tremorsense</a> under Special
Abilities.</p>
    <p><b><a id="treasure"></a>Treasure</b>: This entry in a
monster description describes
how much wealth a creature owns. In most cases, a creature keeps
valuables in its home or lair and has no treasure with it when it
travels. Intelligent creatures that own useful, portable treasure (such
as magic items) tend to carry and use these, leaving bulky items at
home. Treasure can include coins, goods, and items. Creatures can have
varying amounts of each, as follows.</p>
    <p><i>Standard</i>: Refer to the treasure tables and roll d% once
for each type of treasure (Coins, Goods, Items) on the Level section of
the table that corresponds to the creature&rsquo;s Challenge Rating
(for
groups of creatures, use the Encounter Level for the encounter
instead). Some creatures have double, triple, or even quadruple
standard treasure; in these cases, roll for each type of treasure two,
three, or four times.</p>
    <p><i>None</i>: The creature collects no treasure of its own.</p>
    <p><i>Nonstandard</i>: Some creatures have quirks or habits that
affect the types of treasure they collect. These creatures use the same</p>
    <p>treasure tables, but with special adjustments.</p>
    <p><i>Fractional Coins</i>: Roll on the Coins column in the
section corresponding to the creature&rsquo;s Challenge Rating, but
divide
the result as indicated.</p>
    <p><i>% Goods or Items</i>: The creature has goods or items only
some of the time. Before checking for goods or items, roll d% against
the given percentage. On a success, make a normal roll on the
appropriate Goods or Items column (which may still result in no goods
or items).</p>
    <p><i>Double Goods or Items</i>: Roll twice on the appropriate
Goods or Items column.</p>
    <p><i>Parenthetical Notes</i>: Some entries for goods or items
include notes that limit the types of treasure a creature collects.</p>
    <p>When a note includes the word &ldquo;no,&rdquo; it means the
creature does
not collect or cannot keep that thing. If a random roll generates such
a result, treat the result as &ldquo;none&rdquo; instead. </p>
    <p>When a note includes the word &ldquo;only,&rdquo; the creature
goes out of
its way to collect treasure of the indicated type. Treat all results
from that column as the indicated type of treasure.</p>
    <p>It&rsquo;s sometimes necessary to reroll until the right sort
of
item appears. </p>
    <p><b><a id="turn-resistance"></a>Turn Resistance (Ex)</b>: A
creature with this special
quality (usually an undead) is less easily affected by clerics or
paladins. When resolving a turn, rebuke, command, or bolster attempt,
add the indicated number to the creature&rsquo;s Hit Dice total.
    </p>
    <p>Also see <a href="abilitiesAndConditions.html#turn-resistance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Turn Resistance</a> under Special
Abilities.</p>
    <p><b><a id="undead"></a>Undead Type</b>: Undead are
once-living creatures animated
by spiritual or supernatural forces.</p>
    <p><i>Features</i>: An undead creature has the following
features. </p>
    <ul>
      <li>
        <p>12-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 1/2 total Hit Dice (as wizard).</p>
      </li>
      <li>
        <p>Good Will saves.</p>
      </li>
      <li>
        <p>Skill points equal to (4 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, if the undead
creature has an Intelligence score. However, many undead are mindless
and gain no skill points or feats.</p>
      </li>
    </ul>
    <p><i>Traits</i>: An undead creature possesses the following
traits (unless otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>No Constitution score.</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Immunity to all mind-affecting effects (charms,
compulsions,
phantasms, patterns, and morale effects).</p>
      </li>
      <li>
        <p>Immunity to poison, sleep effects, paralysis, stunning,
disease, and death effects.</p>
      </li>
      <li>
        <p>Not subject to critical hits, nonlethal damage, ability
drain, or energy drain. Immune to damage to its physical ability scores
(Strength, Dexterity, and Constitution), as well as to fatigue and
exhaustion effects.</p>
      </li>
      <li>
        <p>Cannot heal damage on its own if it has no Intelligence
score, although it can be healed. Negative energy (such as an inflict
spell) can heal undead creatures. The fast healing special quality
works regardless of the creature&rsquo;s Intelligence score.</p>
      </li>
      <li>
        <p>Immunity to any effect that requires a Fortitude save
(unless
the effect also works on objects or is harmless).</p>
      </li>
      <li>
        <p>Uses its Charisma modifier for <a href="skillsAll.html#concentration" style={{
            "color": "rgb(87, 158, 182)"
          }}>Concentration</a>
checks.</p>
      </li>
      <li>
        <p>Not at risk of death from massive damage, but when reduced
to
0 hit points or less, it is immediately destroyed.</p>
      </li>
      <li>
        <p>Not affected by raise dead and reincarnate spells or
abilities. Resurrection and true resurrection can affect undead
creatures. These spells turn undead creatures back into the living
creatures they were before becoming undead.</p>
      </li>
      <li>
        <p>Proficient with its natural weapons, all simple weapons,
and
any weapons mentioned in its entry.</p>
      </li>
      <li>
        <p>Proficient with whatever type of armor (light, medium, or
heavy) it is described as wearing, as well as all lighter types. Undead
not indicated as wearing armor are not proficient with armor. Undead
are proficient with shields if they are proficient with any form of
armor.</p>
      </li>
      <li>
        <p>Undead do not breathe, eat, or sleep.</p>
      </li>
    </ul>
    <p><b><a id="vermin"></a>Vermin Type</b>: This type includes
insects, arachnids,
other arthropods, worms, and similar invertebrates.</p>
    <p><i>Features</i>: Vermin have the following features. </p>
    <ul>
      <li>
        <p>8-sided Hit Dice.</p>
      </li>
      <li>
        <p>Base attack bonus equal to 3/4 total Hit Dice (as cleric).</p>
      </li>
      <li>
        <p>Good Fortitude saves.</p>
      </li>
      <li>
        <p>Skill points equal to (2 + Int modifier, minimum 1) per
Hit
Die, with quadruple skill points for the first Hit Die, if the vermin
has an Intelligence score. However, most vermin are mindless and gain
no skill points or feats.</p>
      </li>
    </ul>
    <p><i>Traits</i>: Vermin possess the following traits (unless
otherwise noted in a creature&rsquo;s entry). </p>
    <ul>
      <li>
        <p>Mindless: No Intelligence score, and immunity to all
mind-affecting effects (charms, compulsions, phantasms, patterns, and
morale effects).</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Proficient with their natural weapons only.</p>
      </li>
      <li>
        <p>Proficient with no armor.</p>
      </li>
      <li>
        <p>Vermin breathe, eat, and sleep.</p>
      </li>
    </ul>
    <p><b><a id="vulnerability-to-energy"></a>Vulnerability to
Energy</b>: Some creatures have
vulnerability to a certain kind of energy effect (typically either cold
or fire). Such a creature takes half again as much (+50%) damage as
normal from the effect, regardless of whether a saving throw is
allowed, or if the save is a success or failure.</p>
    <p><b><a id="water"></a>Water Subtype</b>: This subtype usually
is used for
elementals and outsiders with a connection to the Elemental Plane of
Water. Creatures with the water subtype always have swim speeds and can
move in water without making Swim checks. A water creature can breathe
underwater and usually can breathe air as well. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      